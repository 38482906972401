import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_ID_KEY,
    uint32.toString(32)
  );
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_TOKEN_KEY,
    uint32.toString(32)
  );
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class TtsApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    accept: "application/json",
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(
      process.env.REACT_APP_DEVICE_TOKEN_KEY
    ),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    // Replace multiple consecutive forward slashes with a single forward slash
    // return url.replace(/\/\/+/g, '/');
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    const headers = { ...TtsApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

   // SIGNUP======
   static async signup(data) {
    let header = { "accept-language": `en` };
    let res = await this.request(`/user/register`, data, "post", header);
    return res;
  }

  // LOGIN======
  static async login(data) {
    let header = { "accept-language": `en` };
    let res = await this.request(`/user/login`, data, "post", header);
    return res;
  }

  // LOGOUT======
  static async logout(authToken) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(`/user/logout`, "", "get", header);
    return res;
  }

  // Forget password======
  static async forgetPassword(data) {
    let header = { "accept-language": `en` };
    let res = await this.request(`/user/forgotPassword`, data, "post", header);
    return res;
  }

   // Reset password======
   static async resetPassword(data) {
    let header = { "accept-language": `en` };
    let res = await this.request(`/user/resetPassword`, data, "post", header);
    return res;
  }

  //GET NOTE LIST
  static async getDetail(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(
      `/admin/studyNotes?page=${pageNumber}`,
      {},
      "get",
      header
    );
    return res;
  }

  //SEARCH NOTE LIST
  static async searchDetail(authToken, searchKey, searchCategory, pageNumber) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(
      `/admin/studyNotes?search=${searchKey}&category=${searchCategory}&page=${pageNumber}`,
      {},
      "get",
      header
    );
    return res;
  }

  //ADD UPDTAE NOTE LIST
  static async addDetail(authToken, data, api_url, method) {
    let header = { "access-token": `${authToken}`, "accept-language": `en` };
    let res = await this.request(`${api_url}`, data, method, header);
    return res;
  }

  //DELETE STUDY NOTE DETAIL
  static async deleteStudyNote(authToken, id) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(
      `/admin/studyNotes/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //GET NOTE DETAIL
  static async getNoteDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(`/admin/studyNotes/${id}`, {}, "get", header);
    return res;
  }

  //ADD Question
  static async postQuestions(authToken, id, data) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(
      `/admin/studyNotes/${id}/question`,
      data,
      "post",
      header
    );
    return res;
  }

  //Update Question
  static async updateQuestions(authToken, id, queId, data) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(
      `/admin/studyNotes/${id}/question/${queId}`,
      data,
      "put",
      header
    );
    return res;
  }

  //DELETE Question
  static async deleteQuestion(authToken, id, queId) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(
      `/admin/studyNotes/${id}/question/${queId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //GET Question details
  static async getQuestionDetail(authToken, id, queId) {
    let header = { "access-token": ` ${authToken}`, "accept-language": `en` };
    let res = await this.request(
      `/admin/studyNotes/${id}/question/${queId}`,
      {},
      "get",
      header
    );
    return res;
  }
}

export default TtsApi;
