export const HeaderData = [
  {
    id: "1",
    name: "HOME",
    link: "/",
  },
  {
    id: "2",
    name: "LIVE CLASSES",
    link: "/live-class",
  },
  {
    id: "3",
    name: "STUDY NOTES",
    link: "/study-notes",
  },
  {
    id: "4",
    name: "PROJECTS",
    link: "/projects",
  },
  {
    id: "5",
    name: "REPOSITORY",
    link: "/repository",
  },
  {
    id: "6",
    name: "SUBSCRIPTION",
    link: "/subscription",
  },
  {
    id: "7",
    name: "SIGNUP",
    link: "/signup",
  },
  {
    id: "8",
    name: "SING_IN",
    link: "/sign-in",
  },
];
