import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import styles from "../StudyNotes/CategoryModal.module.css";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { CATEGORY } from "../../helpers/mockData/Category";

function SearchByCategory({ show, handleClose, setCategory, category }) {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState();

  const handleCheckboxChange = (item) => {
    setSelectedOption({ id: item.id, name: item.category });
  };

  const handleReset = () => {
    setCategory("");
    handleClose();
  };

  const handleSubmit = () => {
    setCategory(selectedOption);
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className={styles.ModalHeading}>
            {t("SELECT_CATEGORY")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {CATEGORY.map((item, index) => {
            return (
              <div className={styles.OptionArea} key={index}>
                <p>{item.category}</p>
                <Form.Check
                  onClick={() => handleCheckboxChange(item)}
                  defaultChecked={category && category.id === item.id}
                  type={"radio"}
                  id={item.id}
                  name="options"
                />
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className={styles.Buttons}>
          <button className="WatchButton" onClick={handleSubmit}>
            {t("DONE")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SearchByCategory;
