export const CATEGORY = [
  {
    id: 1,
    category: "Database Administration",
  },
  {
    id: 2,
    category: "ETL (Extract, Transform & Load)",
  },
  {
    id: 3,
    category: "Data Visualization",
  },
];

export const ANSWER = [
  {
    id: 1,
    Answer: "Option-1",
  },
  {
    id: 2,
    Answer: "Option-2",
  },
  {
    id: 3,
    Answer: "Option-3",
  },
  {
    id: 4,
    Answer: "Option-4",
  },
];
