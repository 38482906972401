import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import styles from "./CategoryModal.module.css";
import Success from "../../Assests/Images/success.png"


function SubmitAnswerModel({ show, handleClose}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={styles.Heading}>
          <Modal.Title className={styles.AlertHeading}>
            {t("Thank you")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.AlertBody}>
          <img src={Success} alt="AlertImg"></img>
          <p>
            Your submission has been success Lorem Ipsum  is simply dummy text
            of the industry.
          </p>
        </Modal.Body>
        <Modal.Footer className={styles.AlertFooter}>
          <button onClick={handleClose} className={styles.AlertYes}>
            {t("OK")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubmitAnswerModel;
