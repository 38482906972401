import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import style from "../Home/Home.module.css";
import { PiPhone } from "react-icons/pi";
import { CiMail } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import Mask from "../../Assests/Images/Mask.png";
import Gallery from "../../Assests/Images/gallery.png";

function Footer() {
  return (
    <MDBFooter>
      <div className="Line"></div>
      <section>
        <MDBContainer className={style.ContainerClass}>
          <img src={Mask} />
          <MDBRow className={style.FooterBody}>
            <MDBCol md="3" lg="4" xl="3" className={style.Contact}>
              <h6>
                Desires to obtain pain of it because it is pain, but
                occasionally circum
              </h6>
              <p>
                <PiPhone color="secondary" icon="home" className="me-3" />
                +1 254-585-576
              </p>
              <p>
                <CiMail color="secondary" icon="envelope" className="me-3" />
                TTS@gmail.com
              </p>
              <p>
                <CiLocationOn color="secondary" icon="phone" className="me-3" />{" "}
                + 01 fransisco
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className={style.Navigation}>
              <h6>Our Navigation</h6>
              <p>Subscription page</p>
              <p>Live classes</p>
              <p> Study notes</p>
              <p> Our Projects</p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className={style.Navigation}>
              <h6>Subscribe</h6>
              <MDBInput
                type="text"
                id="form5Example2"
                placeholder="Your Email ID"
                className={style.formInput}
              />
              <p>
                {" "}
                Our expertise, as well as our passion for web design, sets us
                agenciese
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className={style.Navigation}>
              <h6>Our Gallery</h6>
              <img src={Gallery} />
              <img src={Gallery} />
              <img src={Gallery} />
              <img src={Gallery} />
              <img src={Gallery} />
              <img src={Gallery} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4 my-2"
        style={{ backgroundColor: "#0052AB", color: "white" }}
      >
        Copyright © 2024 TTS. { " "}
       
        All Rights Reserved
      </div>
    </MDBFooter>
  );
}

export default Footer;
