import React, { useState } from "react";
import TopImg from "../../Assests/Images/StudyNote.png";
import styles from "./StudyNotes.module.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StudyNotesIcon from "../../Assests/Images/studyNotesIcon.png";
import { Form } from "react-bootstrap";
import SelectIcon from "../../Assests/Images/SelectIcon.png";
import SearchIcon from "../../Assests/Images/SearchIcon.png";
import Vid1Img from "../../Assests/Images/Vid1Img.png";
import Vid2Img from "../../Assests/Images/Vid2Img.png";
import Footer from "../Footer/Footer";
import SearchByCategory from "./SearchByCategory";
import { useNavigate } from "react-router-dom";


function StudyNotes() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showCategory, setShowCategory] = useState(false);
  const [category, setCategory] = useState();

  const handleCloseCategory = () => setShowCategory(false);
  const handleShowCategory = () => setShowCategory(true);

  const handleChange = () => {};

  return (
    <div className="PageContent">
      <Container className={styles.ContainerClass}>
        {/* Top heading data */}
        <img src={TopImg} className={styles.backgroundImage} alt="Home" />

        <div className={styles.textContent}>
          <Container className="p-0">
            <h1>
              {t("WELCOME_TITLE")}
              <span>Now</span>
            </h1>
            <p>{t("WELCOME_PARA")}</p>

            <button>{t("GET_START_BTN")}</button>
          </Container>
        </div>

        {/* Study notes contant */}
        <div className={styles.StudyNotesHeading}>
          <img src={StudyNotesIcon} alt="StudyNotesIcon" />
          <h1>{t("STUDY NOTES")}</h1>
        </div>

        <div className={styles.SearchAndCreate}>
          <div className={styles.Create}>
            <div
              className={styles.SelectCategory}
              onClick={() => handleShowCategory()}
            >
              {/* {category ? <p>{category}</p> : <p>{t("CATEGORY")}</p>} */}
              <p>{t("SELECT_CATEGORY")}</p>
              <img src={SelectIcon} alt="SelectIcon"></img>
            </div>
          </div>
          <Form.Group className="SearchInput">
            <Form.Control
              type="text"
              placeholder={t("SEARCH")}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
            <div className="SearchIconBox">
              <img
                src={SearchIcon}
                alt="SearchIcon"
                width={"19px"}
                height={"18px"}
              ></img>
            </div>
          </Form.Group>
        </div>

        <div className={styles.NotesAndClassSection}>
          <div className={styles.VideoContent}>
            <div
              className={styles.Video}
              onClick={() => {
                navigate("/study-notes/study-notes-detail");
              }}
            >
              <img src={Vid1Img} alt="Vid1Img"></img>
            </div>

            <div>
              <div className={styles.VideoHeading}>
                <p>Database Analysis</p>
              </div>
              <div className={styles.VideoDescription}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.{" "}
                </p>
              </div>
              <div className={styles.Category}>
                <h1>Category: </h1>
                <p>Database Administration</p>
              </div>
            </div>
          </div>
          <div className="Devider"></div>
          <div className={styles.VideoContent}>
            <img src={Vid2Img} alt="Vid1Img"></img>
            <div>
              <div className={styles.VideoHeading}>
                <p>ETL and Data Pipelines </p>
              </div>
              <div className={styles.VideoDescription}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.{" "}
                </p>
              </div>
              <div className={styles.Category}>
                <h1>Category: </h1>
                <p> ETL (Extract, Transform & Load)</p>
              </div>
            </div>
          </div>
          <div className="Devider"></div>
          <div className={styles.VideoContent}>
            <img src={Vid1Img} alt="Vid1Img"></img>
            <div>
              <div className={styles.VideoHeading}>
                <p>Power BI</p>
              </div>
              <div className={styles.VideoDescription}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.{" "}
                </p>
              </div>
              <div className={styles.Category}>
                <h1>Category: </h1>
                <p> Data Visualization</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />

      <SearchByCategory
        show={showCategory}
        handleClose={handleCloseCategory}
        category={category}
        setCategory={setCategory}
      />
    </div>
  );
}

export default StudyNotes;
