import React, { useRef, useState } from "react";
import styles from "./LiveClass.module.css";
import Footer from "../Footer/Footer";
import SearchByCategory from "../Projects/SearchByCategory";
import SelectIcon from "../../Assests/Images/SelectIcon.png";
import SearchIcon from "../../Assests/Images/SearchIcon.png";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Form } from "react-bootstrap";
import TopImg from "../../Assests/Images/LiveClass.png";
import { CiClock2 } from "react-icons/ci";
import AlertClass from "./AlertClass";

function LiveClass() {
  const { t } = useTranslation();

  const [showCategory, setShowCategory] = useState(false);
  const [category, setCategory] = useState();

  const [date, setDate] = useState("");
  const dateInputRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleShow = () => {
    console.log("gwergfrtr");
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    setDate(e.target.value);
  };

  const handleCloseCategory = () => setShowCategory(false);
  const handleShowCategory = () => setShowCategory(true);

  return (
    <div className="PageContent">
      <Container className={styles.ContainerClass}>
        <img src={TopImg} className={styles.backgroundImage} alt="Home" />
        <div className={styles.textContent}>
          <Container className="p-0">
            <h1>Join our online classes</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy text of printing and simply
              dummy text typesetting industry Lorem
            </p>
          </Container>
        </div>

        <div className={styles.SearchAndCreate}>
          <Form.Group className="SearchInput">
            <Form.Control
              type="text"
              placeholder={t("SEARCH")}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
            <div className="SearchIconBox">
              <img
                src={SearchIcon}
                alt="SearchIcon"
                width={"19px"}
                height={"18px"}
              ></img>
            </div>
          </Form.Group>
          <div className={styles.Create}>
            <div
              className={styles.SelectCategory}
              onClick={() => handleShowCategory()}
            >
              {/* {category ? <p>{category}</p> : <p>{t("CATEGORY")}</p>} */}
              <p>{t("SELECT_CATEGORY")}</p>
              <img src={SelectIcon} alt="SelectIcon"></img>
            </div>
          </div>
          <div className={styles.Create}>
            <input
              type="date"
              onChange={handleChange}
              ref={dateInputRef}
              className={styles.SelectCategory}
              value={date}
            />
          </div>
        </div>

        <div className={styles.joinInfo}>
          <div className={styles.classHeading}>
            <h1>Data Mangement Class</h1>
            <p>10 March, 2024</p>
          </div>
          <h4>Database Administration</h4>
          <div className={styles.classTimer}>
            <p>
              <CiClock2 style={{ color: "#038E9F" }} /> Start time : 12:00 PM
            </p>
            <p>
              <CiClock2 style={{ color: "#038E9F" }} /> End time : 2:00 PM
            </p>
          </div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply dummy text of printing and simply
            dummy text typesetting industry Lorem
          </p>

          <button
            className={styles.JoinNow}
            onClick={() => {
              handleShow();
            }}
          >
            JOIN NOW
          </button>
        </div>

        <div className={styles.joinInfo}>
          <div className={styles.classHeading}>
            <h1>My SQL Database Administration</h1>
            <p>10 April, 2024</p>
          </div>
          <h4>Database Administration</h4>
          <div className={styles.classTimer}>
            <p>
              <CiClock2 style={{ color: "#038E9F" }} /> Start time : 12:00 PM
            </p>
            <p>
              <CiClock2 style={{ color: "#038E9F" }} /> End time : 2:00 PM
            </p>
          </div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply dummy text of printing and simply
            dummy text typesetting industry Lorem
          </p>

          <button className={styles.Joined}>JOINED</button>
        </div>
        <div className="ViewMoreButton">
          <button>View more</button>
        </div>
      </Container>
      <Footer />
      <SearchByCategory
        show={showCategory}
        handleClose={handleCloseCategory}
        category={category}
        setCategory={setCategory}
      />
      <AlertClass show={show} handleClose={handleClose} />
    </div>
  );
}

export default LiveClass;
