import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Login.module.css";
import { Form } from "react-bootstrap";
import { ValidationSchema } from "../common/ValidationSchema";
import LoginImg from "../Assests/Images/loginImg.png";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Toster from "../common/Toster/Toster";
import { useDispatch } from "react-redux";
import { fetchForgetPassword } from "../redux/slices/userSlice";

function ForgetPassword() {
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // const handelSubmit = (data) => {
  //   setIsLoading(true);
  //   const PostData = new FormData();
  //   PostData.append("email", data.EMAIL);
  //   dispatch(fetchForgetPassword(PostData)).then((res) => {
  //     setIsLoading(false);
  //     const response = res?.payload;
  //     if (response?.status === true) {
  //       Toster(response?.message, "success");
  //       navigate("/new-password");
  //     } else {
  //       Toster(t(response?.message), "error");
  //     }
  //   });
  // };

  return (
    <div className={styles.MainPage}>
      <div className="row">
        <div className="col-md-5 p-0">
          <div className={styles.loginContent}>
            {/* {isLoading ? <Loader /> : ""} */}
            <div className="ArrowIconClass">
              <Link to="/sign-in" className={styles.signup}>
                <FaArrowLeft size={50} className="BackIcon" />
              </Link>
            </div>
            <div className={styles.FieldSection}>
              <div className={styles.Heading}>
                <h1>{t("FORGET_PASSWORD")}</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.{" "}
                </p>
              </div>
              <div className={styles.LoginInputSection}>
                <Form noValidate >
                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("EMAIL")}
                    </Form.Label>
                    <Form.Control
                      placeholder={t("ENTER_EMAIL")}
                      {...register("EMAIL", ValidationSchema.EMAIL)}
                      isInvalid={!!formState.errors.EMAIL}
                      onChange={(e) => {
                        setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.EMAIL?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Link to="/new-password">
                  <button type="submit" className={styles.SubmitButton}>
                    {t("Submit")}
                  </button>
                  </Link>
                  <button type="submit" className={styles.CancelButton}>
                    {t("CANCEL")}
                  </button>
                </Form>
              </div>
              <div className={styles.NoAccount}>
                <p>
                  Already have an account?
                  <Link to="/sign-in">
                    {" "}
                    <span>{t("SING_IN")}</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 d-md-block d-none">
          <img src={LoginImg} alt="loginImg" className={styles.LoginImage} />
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
