import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Login.module.css";
import { Form } from "react-bootstrap";
import { ValidationSchema } from "../common/ValidationSchema";
import Loader from "../common/loader/Loader";
import LoginImg from "../Assests/Images/loginImg.png";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import SuccessSignup from "./SuccessSignup";
import Toster from "../common/Toster/Toster";
import { useDispatch } from "react-redux";
import { userSignup } from "../redux/slices/userSlice";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa6";

function Signup() {
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);

  const [formdata, setFormdata] = useState('');


  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  // const handelSubmit = (formdata) => {
  //   setShow(true);
  //   setFormdata(formdata)
  // };

  const handleAccount = () => {
    setShow(false);
    console.log("Submitting form with data:", formdata);
    // setIsLoading(true);
    // const PostData = new FormData();
    // PostData.append("fullname", formdata.NAME);
    // PostData.append("email", formdata.EMAIL);
    // PostData.append("password", formdata.PASSWORD);
    // PostData.append("confirm_password", formdata.CNFPASSWORD);
    // dispatch(userSignup(PostData)).then((res) => {
    //   setIsLoading(false);
    //   const response = res?.payload;

    //   if (response?.status === true) {
    //     Toster(response?.message, "success");
    //     setShow(false);
    //     navigate("/study-notes");
    //   } else {
    //     Toster(t(response?.data.message), "error");
    //   }
    // });
  };

  return (
    <div className={styles.MainPage}>
      <div className="row">
        <div className="col-md-5 p-0">
          <div className={styles.loginContent}>
            {/* {isLoading ? <Loader /> : ""} */}
            <div className="ArrowIconClass">
              <Link to="/sign-in" className={styles.signup}>
                <FaArrowLeft size={50} className="BackIcon" />
              </Link>
            </div>
            <div className={styles.FieldSection}>
              <div className={styles.Heading}>
                <h1>{t("SIGNUP")}</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.{" "}
                </p>
              </div>
              <div className={styles.LoginInputSection}>
                <Form noValidate >
                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("NAME")}
                    </Form.Label>
                    <Form.Control
                      placeholder={t("ENTER_NAME")}
                      {...register("NAME", ValidationSchema.NAME)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.NAME?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("EMAIL")}
                    </Form.Label>
                    <Form.Control
                      placeholder={t("ENTER_EMAIL")}
                      {...register("EMAIL", ValidationSchema.EMAIL)}
                      isInvalid={!!formState.errors.EMAIL}
                      onChange={(e) => {
                        setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.EMAIL?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("PASSWORD")}
                    </Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder={t("ENTER_PASSWORD")}
                      {...register("PASSWORD", ValidationSchema.PASSWORD)}
                      isInvalid={!!formState.errors.PASSWORD}
                      onChange={(e) => {
                        setValue(
                          "PASSWORD",
                          e.target.value.replace(/\s/g, ""),
                          { shouldValidate: true }
                        );
                      }}
                    />
                    {showPassword ? (
                      <FaRegEye onClick={() => setShowPassword(false)} />
                    ) : (
                      <FaRegEyeSlash onClick={() => setShowPassword(true)} />
                    )}
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.PASSWORD?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("CONFIRM_PASSWORD")}
                    </Form.Label>
                    <Form.Control
                      type={showCnfPassword ? "text" : "password"}
                      placeholder={t("ENTER_CONFIRM_PASSWORD")}
                      {...register("CNFPASSWORD", ValidationSchema.CNFPASSWORD)}
                      isInvalid={!!formState.errors.CNFPASSWORD}
                      onChange={(e) => {
                        setValue(
                          "CNFPASSWORD",
                          e.target.value.replace(/\s/g, ""),
                          { shouldValidate: true }
                        );
                      }}
                    />
                    {showCnfPassword ? (
                      <FaRegEye onClick={() => setShowCnfPassword(false)} />
                    ) : (
                      <FaRegEyeSlash onClick={() => setShowCnfPassword(true)} />
                    )}
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.CNFPASSWORD?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
                  <button
                    className={styles.SubmitButton}
                    type="submit"
                    onClick={handleShow}
                  >
                    {t("SIGNUP")}
                  </button>
              </div>
              <div className={styles.NoAccount}>
                <p>
                  Already have an account?{" "}
                  <Link to="/sign-in">
                    <span>{t("SING_IN")}</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 d-md-block d-none">
          <img src={LoginImg} alt="loginImg" className={styles.LoginImage} />
        </div>
      </div>
      <SuccessSignup show={show} handleClose={handleClose} handleAccount={handleAccount}/>
    </div>
  );
}

export default Signup;
