import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Login.module.css";
import { Form } from "react-bootstrap";
import { ValidationSchema } from "../common/ValidationSchema";
import Loader from "../common/loader/Loader";
import LoginImg from "../Assests/Images/loginImg.png";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../common/Toster/Toster";
import { fetchResetPassword } from "../redux/slices/userSlice";
import OtpInput from "react-otp-input";

function NewPassword() {
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const Token = useSelector((state) => state.user.userToken);
  console.log("token", Token)

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const handleOtpChange = (value) => {
    let numbers = /^[0-9]+$/;
    if (value.match(numbers) || value === "") {
      setOtp(value);
      if (value.length !== 6) {
        setOtpError("ERROR_OTP");
      } else {
        setOtpError("");
      }
    } else {
      setOtpError("VALID_OTP");
    }
  };

  // const handelSubmit = (data) => {
  //   setIsLoading(true);
  //   const PostData = new FormData();
  //   PostData.append("forgot_pass_code", otp);
  //   PostData.append("password", data.PASSWORD);
  //   PostData.append("token", Token);
  //   dispatch(fetchResetPassword(PostData)).then((res) => {
  //     setIsLoading(false);
  //     const response = res?.payload;
  //     if (response?.status === true) {
  //       Toster(response?.message, "success");
  //       navigate("/sign-in");
  //     } else {
  //       Toster(t(response?.message), "error");
  //     }
  //   });
  // };

  const otpInputStyles = {
    width: "40px",
    height: "40px",
    textAlign: "center",
    marginRight: "25px",
  };
  return (
    <div className={styles.MainPage}>
      <div className="row">
        <div className="col-md-5 p-0">
          <div className={styles.loginContent}>
            {/* {isLoading ? <Loader /> : ""} */}
            <div className="ArrowIconClass">
              <Link to="/forget-password" className={styles.signup}>
                <FaArrowLeft size={50} className="BackIcon" />
              </Link>
            </div>
            <div className={styles.FieldSection}>
              <div className={styles.Heading}>
                <h1>{t("New password")}</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.{" "}
                </p>
              </div>
              <div className={styles.LoginInputSection}>
                <Form noValidate >
                  <Form.Group className="OtpInput">
                    <OtpInput
                      inputStyle={otpInputStyles}
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={4}
                      renderInput={(props) => (
                        <input
                          {...props}
                          onKeyDown={(e) => {
                            const key = e.key;
                            if (
                              !(key >= "0" && key <= "9") &&
                              key !== "Backspace"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                    {/* {otpError && <p className={styles.error}>{t(otpError)}</p>} */}
                  </Form.Group>

                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("PASSWORD")}
                    </Form.Label>
                    <Form.Control
                      // type={showPassword ? "text" : "password"}
                      type="password"
                      placeholder={t("ENTER_PASSWORD")}
                      {...register("PASSWORD", ValidationSchema.PASSWORD)}
                      isInvalid={!!formState.errors.PASSWORD}
                      onChange={(e) => {
                        setValue(
                          "PASSWORD",
                          e.target.value.replace(/\s/g, ""),
                          { shouldValidate: true }
                        );
                      }}
                    />
                    {/* {showPassword ? (
                    <FaRegEye onClick={() => setShowPassword(false)} />
                  ) : (
                    <FaRegEyeSlash onClick={() => setShowPassword(true)} />
                  )} */}
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.PASSWORD?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("CONFIRM_PASSWORD")}
                    </Form.Label>
                    <Form.Control
                      // type={showPassword ? "text" : "password"}
                      type="password"
                      placeholder={t("ENTER_CONFIRM_PASSWORD")}
                      {...register("PASSWORD", ValidationSchema.PASSWORD)}
                      isInvalid={!!formState.errors.PASSWORD}
                      onChange={(e) => {
                        setValue(
                          "PASSWORD",
                          e.target.value.replace(/\s/g, ""),
                          { shouldValidate: true }
                        );
                      }}
                    />
                    {/* {showPassword ? (
                    <FaRegEye onClick={() => setShowPassword(false)} />
                  ) : (
                    <FaRegEyeSlash onClick={() => setShowPassword(true)} />
                  )} */}
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.PASSWORD?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <button type="submit" className={styles.SubmitButton}>
                    {t("Submit")}
                  </button>
                </Form>
              </div>

              <div className={styles.NoAccount}>
                <p>
                  Already have an account?{" "}
                  <Link to="/sign-in">
                    <span>{t("SING_IN")}</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 d-md-block d-none">
          <img src={LoginImg} alt="loginImg" className={styles.LoginImage} />
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
