import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./NavHeader.module.css";
import { HeaderData } from "./HeaderData";
import "../../Assests/Styles/Common.css";
import TtsIcon from "../../Assests/Images/TTSIcon.png";
import { useTranslation } from "react-i18next";
import BellIcon from "../../Assests/Images/bell.png";
import ProfileIcon from "../../Assests/Images/profile.png";
import { Link } from "react-router-dom";

function NavHeader() {
  const { t } = useTranslation();
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <>
      {location.pathname === "/sign-in" ||
      location.pathname === "/forget-password" ||
      location.pathname === "/new-password" ||
      location.pathname === "/signup" ? (
        ""
      ) : (
        <div className="Navheader">
          <Navbar expand="lg" className={styles.test} data-bs-theme="dark">
            <Container className={styles.hideContainer}>
              <div className={styles.showLogo}>
                <div className={styles.topNavLogo}>
                  <Link to="/">
                    <img
                      alt=""
                      src={TtsIcon}
                      className={styles.navbarLogoImg}
                    />
                  </Link>
                </div>
              </div>
              <div className="test">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
              </div>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={styles.navItem}>
                  {HeaderData.map(
                    (item) =>
                      (isHomePage ||
                        (item.name !== "SIGNUP" &&
                          item.name !== "SING_IN" &&
                          item.name !== "HOME")) && ( // Check if it's home page or not SIGNUP or SING_IN
                        <div key={item.id} className={styles.navLinks}>
                          <NavLink
                            to={item.link}
                            className={`${styles.navItems} ${
                              item.name === "SIGNUP"
                                ? styles.signupButton
                                : "" || item.name === "SING_IN"
                                ? styles.signinButton
                                : ""
                            }`}
                          >
                            {t(item.name)}
                          </NavLink>
                        </div>
                      )
                  )}
                  {!isHomePage && (
                    <>
                      <img
                        src={BellIcon}
                        alt="Bell"
                        className={styles.bellIcon}
                      />
                      <img
                        src={ProfileIcon}
                        alt="Profile"
                        className={styles.profileIcon}
                      />
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      )}
    </>
  );
}

export default NavHeader;
