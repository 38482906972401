import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Login.module.css";
import { Form } from "react-bootstrap";
import { ValidationSchema } from "../common/ValidationSchema";
import Loader from "../common/loader/Loader";
import LoginImg from "../Assests/Images/loginImg.png";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Toster from "../common/Toster/Toster";
import { useDispatch } from "react-redux";
import { userLogin } from "../redux/slices/userSlice";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa6";

function Signin() {
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // const handelSubmit = (data) => {
  //   setIsLoading(true);
  //   const PostData = new FormData();
  //   PostData.append("email", data.EMAIL);
  //   PostData.append("password", data.PASSWORD);
  //   dispatch(userLogin(PostData)).then((res) => {
  //     setIsLoading(false);
  //     const response = res?.payload;
  //     console.log("response",response)
  //     if (response?.status === true) {
  //       Toster(response?.message, "success");
  //       navigate("/study-notes");
  //     } else {
  //       Toster(t(response?.message), "error");
  //     }
  //   });
  // };

  return (
    <div className={styles.MainPage}>
      <div className="row">
        <div className="col-md-5 p-0">
          <div className={styles.loginContent}>
            {/* {isLoading ? <Loader /> : ""} */}
              <div className="ArrowIconClass">
                <Link to="/" className={styles.signup}>
                  <FaArrowLeft size={50} className="BackIcon" />
                </Link>
              </div>
            <div className={styles.FieldSection}>
              <div className={styles.Heading}>
                <h1>{t("SING_IN")}</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.{" "}
                </p>
              </div>
              <div className={styles.LoginInputSection}>
                <Form noValidate >  
                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("EMAIL")}
                    </Form.Label>
                    <Form.Control
                      placeholder={t("ENTER_EMAIL")}
                      {...register("EMAIL", ValidationSchema.EMAIL)}
                      isInvalid={!!formState.errors.EMAIL}
                      onChange={(e) => {
                        setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.EMAIL?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="LoginInput">
                    <Form.Label className={styles.Label}>
                      {t("PASSWORD")}
                    </Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder={t("ENTER_PASSWORD")}
                      {...register("PASSWORD", ValidationSchema.PASSWORD)}
                      isInvalid={!!formState.errors.PASSWORD}
                      onChange={(e) => {
                        setValue(
                          "PASSWORD",
                          e.target.value.replace(/\s/g, ""),
                          { shouldValidate: true }
                        );
                      }}
                    />
                    {showPassword ? (
                    <FaRegEye onClick={() => setShowPassword(false)} />
                  ) : (
                    <FaRegEyeSlash onClick={() => setShowPassword(true)} />
                  )}
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.PASSWORD?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <button type="submit" className={styles.LoginButton}>
                    {t("SING_IN")}
                  </button>
                </Form>
              </div>
              <div className={styles.ForgetPassword}>
                <Link to="/forget-password" >
                  <p>{t("FORGET_PASSWORD")}</p>
                </Link>
              </div>
              <div className={styles.NoAccount}>
                <p>
                  Don't have an account? {" "}
                  <Link to="/signup" >
                    <span>{t("SIGNUP")}</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 d-md-block d-none">
          <img src={LoginImg} alt="loginImg" className={styles.LoginImage} />
        </div>
      </div>
    </div>
  );
}

export default Signin;
