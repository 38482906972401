import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TtsApi from "../../helpers/Api";

const initialState = {
  currentUser: {},
  isLoading: false,
  userToken: null,
  success: false,
  errorCode: null,
  error: null,
  user_type: null,
};

// Thunk for user signup
export const userSignup = createAsyncThunk(
  "user/userSignup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TtsApi.signup(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user signin
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TtsApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user forget password
export const fetchForgetPassword = createAsyncThunk(
  "user/fetchForgetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TtsApi.forgetPassword(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user reset password
export const fetchResetPassword = createAsyncThunk(
  "user/fetchResetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TtsApi.resetPassword(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.currentUser = {};
      state.isLoading = false;
      state.userToken = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    // user signup===============
    builder.addCase(userSignup.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userSignup.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.userToken = response?.data.token;
      state.currentUser = response?.data;
      state.errorCode = response?.data.code;
      state.success = true;
    });

    builder.addCase(userSignup.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user Login===============
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.userToken = response?.data.token;
      state.currentUser = response?.data;
      state.errorCode = response?.data.code;
      state.success = true;
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user forget password===============
    builder.addCase(fetchForgetPassword.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchForgetPassword.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.userToken = response?.data?.token;
      state.errorCode = response?.data.code;
      state.success = true;
    });

    builder.addCase(fetchForgetPassword.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user reset password===============
    builder.addCase(fetchResetPassword.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchResetPassword.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.userToken = response?.data?.token;
      state.errorCode = response?.data.code;
      state.success = true;
    });

    builder.addCase(fetchResetPassword.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { userLogoutClear } = userSlice.actions;
export default userSlice.reducer;
