import React from "react";
import Footer from "../Footer/Footer";
import { Container } from "react-bootstrap";
import styles from "./Repository.module.css";
import Folder from "../../Assests/Images/folder.png";
import { useNavigate } from "react-router-dom";

function Repository() {
  const navigate = useNavigate();

  return (
    <div>
      <Container className={styles.ContainerClass}>
        {/* Top heading data */}
        <div className={styles.textContent}>
          <h1>All Repository</h1>
          <p>
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature.
          </p>

          <div className={styles.joinInfo}>
            <img
              src={Folder}
              alt=""
              onClick={() => {
                navigate("/folder");
              }}
            />
            <img src={Folder} alt="" />
            <img src={Folder} alt="" />
            <img src={Folder} alt="" />
            <img src={Folder} alt="" />
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default Repository;
