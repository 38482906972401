export const CardsJSON = [
  {
    Title: "Basic",
    number: "$9.99",
    duration: "1/Month",
    data: "More-or-less normal distribution, More-or-less normal distribution, More-or-less normal distribution, Passages of Lorem Ipsum available, Comes from a line in section 1.10.32.",
    color: "#0052AB",
  },
  {
    Title: "Advance",
    number: "$25.99",
    duration: "3/Month’s",
    data: "More-or-less normal distribution, More-or-less normal distribution, More-or-less normal distribution,  Passages of Lorem Ipsum available, Comes from a line in section 1.10.32.",
    color: "#038E9F",
  },
  {
    Title: "Standard",
    number: "$40.99",
    duration: "6/Month’s",
    data: "More-or-less normal distribution, More-or-less normal distribution, More-or-less normal distribution, Passages of Lorem Ipsum available, Comes from a line in section 1.10.32.",
    color: "#0052AB",
  },
  {
    Title: "Premium",
    number: "$65.99",
    duration: "Premium",
    data: "More-or-less normal distribution, More-or-less normal distribution, More-or-less normal distribution, Passages of Lorem Ipsum available, Comes from a line in section 1.10.32.",
    color: "#038E9F",
  },
];
