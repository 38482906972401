import React from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./SubscribeModel.module.css";
import { useTranslation } from "react-i18next";
import Visa from "../../Assests/Images/visa.png";
import Money from "../../Assests/Images/money.png";
import Paypal from "../../Assests/Images/paypal.png";
import { Form } from "react-bootstrap";

function SubscribeModel({ show, handleClose }) {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={styles.Heading}>
          <Modal.Title className={styles.AlertHeading}>
            Subscription Now
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.AlertBody}>
          <div className={styles.PaymentMethod}>
            <div>
              <input
                type="radio"
                value="Paypal"
                style={{ marginRight: "4px" }}
              />{" "}
              <img src={Paypal} alt="" />
            </div>
          </div>
          <div className={styles.PaymentMethod}>
            <div>
              <input
                type="radio"
                value="Credit"
                style={{ marginRight: "4px" }}
              />{" "}
              Credit or debit card
            </div>
            <div>
              <img src={Visa} alt=""   style={{ marginRight: "4px" }}/>
              <img src={Money} alt="" />
            </div>
          </div>
          <div className="py-3">
            <Form.Group className="LoginInput">
              <Form.Label className={styles.Label}>
                {t("Card Number")}
              </Form.Label>
              <Form.Control
                placeholder={t("Enter card number")}
                // {...register("EMAIL", ValidationSchema.EMAIL)}
                // isInvalid={!!formState.errors.EMAIL}
                // onChange={(e) => {
                //   setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                //     shouldValidate: true,
                //   });
                // }}
              />
            </Form.Group>
            <div className={styles.PaymentMethod}>
              <Form.Group className="LoginInput">
                <Form.Label className={styles.Label}>
                  {t("Expiry date")}
                </Form.Label>
                <Form.Control
                  placeholder={t("Enter expiry date")}
                  // {...register("EMAIL", ValidationSchema.EMAIL)}
                  // isInvalid={!!formState.errors.EMAIL}
                  // onChange={(e) => {
                  //   setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                  //     shouldValidate: true,
                  //   });
                  // }}
                />
              </Form.Group>
              <Form.Group className="LoginInput">
                <Form.Label className={styles.Label}>{t("CVV")}</Form.Label>
                <Form.Control
                  placeholder={t("Enter CVV")}
                  // {...register("EMAIL", ValidationSchema.EMAIL)}
                  // isInvalid={!!formState.errors.EMAIL}
                  // onChange={(e) => {
                  //   setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                  //     shouldValidate: true,
                  //   });
                  // }}
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.AlertFooter}>
          <button onClick={handleClose} className={styles.AlertYes}>
            {t("Pay Now")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubscribeModel;
