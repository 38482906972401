import React from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./AlertClass.module.css";
import { useTranslation } from "react-i18next";
import Congrats from "../../Assests/Images/Congrats.png";

function AlertClass({ show, handleClose }) {
  const { t } = useTranslation();
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={styles.Heading}>
          <Modal.Title className={styles.AlertHeading}>
            {t("Alert")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.AlertBody}>
          <img src={Congrats} alt="AlertImg"></img>
          <p>
            <span>Congratulation</span> to join this class, meeting link will be send out to
            your given email,{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className={styles.AlertFooter}>
          <button onClick={handleClose} className={styles.AlertYes}>
            {t("OK")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AlertClass;
