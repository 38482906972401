import React from "react";
import { Carousel, Row, Col, Container } from "react-bootstrap";
import { CardsJSON } from "./CardsJSON";
import styles from "./Home.module.css";

function Catogories() {
  // Divide CardsJSON into chunks of 3 for carousel display
  const chunkSize = 4;
  const chunks = [];
  for (let i = 0; i < CardsJSON.length; i += chunkSize) {
    chunks.push(CardsJSON.slice(i, i + chunkSize));
  }

  return (
    <div className={styles.CatogoryPage}>
      <div className={styles.Heading}>
        <h1>Our Categories</h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text.
        </p>
      </div>
      <div className={styles.DashboardCard}>
        <Container>
          <Carousel style={{ height: "400px" }}>
            {chunks.map((chunk, index) => (
              <Carousel.Item key={index}>
                <Row>
                  {chunk.map((item, index) => (
                    <Col xs={12} sm={6} md={4} lg={3} key={index}>
                      <div className={styles.DashboardCardInner}>
                        <img src={item.icon} alt={item.title} />{" "}
                        <h1>{item.title}</h1>
                        <p>{item.desc}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </div>
    </div>
  );
}

export default Catogories;
