import React from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./SuccessModel.module.css";
import { useTranslation } from "react-i18next";
import AlertImg from "../Assests/Images/AlertImg.png";

function SuccessSignup({ show, handleAccount, handleClose }) {
  const { t } = useTranslation();



  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={styles.Heading}>
          <Modal.Title className={styles.AlertHeading}>
            {t("Success")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.AlertBody}>
          <img src={AlertImg} alt="AlertImg"></img>
          <p>Your account has been successfully.</p>
        </Modal.Body>
        <Modal.Footer className={styles.AlertFooter}>
          <button onClick={handleAccount} className={styles.AlertYes}>
            {t("OK")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SuccessSignup;
