import React, { useState } from "react";
import styles from "./Subscription.module.css";
import { CardsJSON } from "./CardsJSON";
import { Row, Col } from "react-bootstrap";
import Footer from "../Footer/Footer";
import SubscribeModel from "./SubscribeModel";

function Subscriptions() {

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div>
      <div className={styles.SubscriptionPage}>
        <h2>Subscriptions</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing</p>
        <Row>
          {CardsJSON.map((items, index) => {
            return (
              <Col
                lg={3}
                xl={3}
                md={6}
                sm={12}
                key={index}
                className={styles.Column}
              >
                <div className={styles.Plan}>
                  <h1>{items.Title}</h1>
                </div>
                <div className={styles.DashboardCardInner}>
                  <h1>{items.number}</h1>
                  <span>{items.duration}</span>
                  <ul style={{ textAlign: "left", padding: "0" }}>
                    {items.data.split(", ").map((dataItem, dataIndex) => (
                      <li key={dataIndex}>{dataItem}</li>
                    ))}
                  </ul>
                  <button
                    className="WatchButton"
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    Subscribe Now
                  </button>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      <Footer />
      <SubscribeModel show={show} handleClose={handleClose}/>
    </div>
  );
}

export default Subscriptions;
