import React from "react";
import DevicesImg from "../../Assests/Images/Devices.png";
import styles from "./Home.module.css";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function VerbalTopics() {
    
  const { t } = useTranslation();

  return (
    <Container className={styles.ContainerClass}>
      <Row className={styles.HomeContent}>
        <Col md={6}>
          <div className={styles.TopicHeading}>
            <h1>Master every TTS Verbal  topic</h1>
            <div className="line"></div>
            <p>Develop the confidence you need for test day.</p>
            <ul>
              <li>Comprehensive, easy-to-understand verbal lessons</li>
              <li>More than 400 instructor-led HD Videos</li>
              <li>
                Realistic example questions covering every topic and skill
                level.
              </li>
              <li>
                Insider knowledge for every verbal question type and trap.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={6}>
          <div className={styles.Devices}>
            <img src={DevicesImg} alt="DevicesImg" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default VerbalTopics;
