import React from "react";
import Footer from "../Footer/Footer";
import { Container } from "react-bootstrap";
import styles from "./Repository.module.css";
import Pdf from "../../Assests/Images/pdf.png";
import Txt from "../../Assests/Images/Txt.png";
import Ppt from "../../Assests/Images/ppt.png";
import Xls from "../../Assests/Images/Xls.png";
import Notes from "../../Assests/Images/note.png";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function FolderPage() {
    
  return (
    <div>
      <Container className={styles.ContainerClass}>
        {/* Top heading data */}
        <div className="ArrowIconClass p-0 ">
          <Link to="/study-notes" className={styles.signup}>
            <FaArrowLeft size={50} className={styles.BackIcon} />
          </Link>
          <h1 className={styles.heading}>Folder-1</h1>
        </div>

        <div className={styles.FolderGroup}>
          <img src={Pdf} alt="" />
          <img src={Txt} alt="" />
          <img src={Ppt} alt="" />
          <img src={Xls} alt="" />
          <img src={Notes} alt="" />
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default FolderPage;
