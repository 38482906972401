import React from "react";
import styles from "./Home.module.css";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import girl from "../../Assests/Images/girl.png";

function TopHeading() {
  const { t } = useTranslation();

  return (
    <Container className={styles.ContainerClass}>
      <Row className={styles.HomeContent}>
        <Col md={7}>
          <div className={styles.NotesAndClassSection}>
            <div className={styles.ClassInfo}>
              <h1>
                Online learning made <span>easy</span> and{" "}
                <span>accessible</span> for everyone
              </h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div className={styles.SubjectDescription}>
              <div className="buttonGroup">
                <button className="StartButton">{t("GET_START")}</button>
                <button className="WatchButton">{t("WATCH_VIDEO")}</button>
              </div>
              <p>5-DAY FULL ACCESS TRIAL FOR FREE</p>
            </div>
          </div>
        </Col>
        <Col md={5}>
          <div className={styles.ImageSection}>
            <img src={girl} alt="girl" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default TopHeading;
