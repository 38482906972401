import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import NavHeader from "../appComponent/NavHeader/NavHeader";
import Home from "../appComponent/Home/Home";
import Signin from "../authComponent/Signin";
import ForgetPassword from "../authComponent/ForgetPassword";
import NewPassword from "../authComponent/NewPassword";
import Signup from "../authComponent/Signup";
import StudyNotes from "../appComponent/StudyNotes/StudyNotes";
import StudyNotesDetails from "../appComponent/StudyNotes/StudyNotesDetails";
import Projects from "../appComponent/Projects/Projects";
import ProjectDetails from "../appComponent/Projects/ProjectDetails";
import LiveClass from "../appComponent/LiveClassess/LiveClass";
import Subscriptions from "../appComponent/Subscriptions/Subscriptions";
import Repository from "../appComponent/Repository/Repository";
import FolderPage from "../appComponent/Repository/Folder";
import { useSelector } from "react-redux";

export const Router = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, [pathname]);

    return null;
  };

  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <NavHeader />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          path="sign-in"
          element={<Signin />}
        />
        <Route
          path="/forget-password"
          element={<ForgetPassword />}
        />
        <Route
          path="/new-password"
          element={<NewPassword />}
        />
        <Route
          path="/signup"
          element={<Signup />}
        />
        <Route
          path="/study-notes"
          element={<StudyNotes />}
        />
        <Route
          path="/study-notes/study-notes-detail"
          element={<StudyNotesDetails />}
        />
        <Route
          path="/projects"
          element={<Projects />}
        />
        <Route
          path="/projects/projects-detail"
          element={<ProjectDetails />}
        />
        <Route
          path="/live-class"
          element={<LiveClass />}
        />
        <Route
          path="/subscription"
          element={<Subscriptions />}
        />
        <Route
          path="/repository"
          element={<Repository />}
        />
        <Route
          path="/folder"
          element={<FolderPage />}
        />
      </Routes>
    </BrowserRouter>
  );
};
