import Image1 from "../../Assests/Images/category1.png"
import Image2 from "../../Assests/Images/category4.png"

export const CardsJSON = [
  {
    icon: Image1,
    title: "Database Administration",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy text",
  },
  {
    icon: Image2,
    title: "ETL (Extract, Transform & Load)",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    icon: Image1,
    title: "Data Visualization",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy text",
  },
  {
    icon: Image2,
    title: "Artificial intelligence",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy text",
  },
  {
    icon: Image1,
    title: "Database Administration",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy text",
  },
  {
     icon: Image2,
    title: "ETL (Extract, Transform & Load)",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    icon: Image1,
    title: "Data Visualization",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy text",
  },
  {
    icon: Image2,
    title: "Artificial intelligence",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy text",
  },
];
