import React, { useState } from "react";
import TopImg from "../../Assests/Images/questionDetail.png";
import styles from "./Projects.module.css";
import Styles from "./ProjectDetail.module.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import QuesAnsIcon from "../../Assests/Images/QueAnsIcon.png";
import Open from "../../Assests/Images/open.png";
import Close from "../../Assests/Images/close.png";
import Footer from "../Footer/Footer";
import SubmitAnswerModel from "../StudyNotes/SubmitAnserModel";

function ProjectDetails() {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState("");
  const [optionsOpen, setOptionsOpen] = useState(false);

  const options = [
    { value: "paris", label: "Paris" },
    { value: "berlin", label: "Berlin" },
    { value: "rome", label: "Rome" },
    { value: "london", label: "London" },
  ];

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const toggleOptions = () => {
    setOptionsOpen(!optionsOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Selected option:", selectedOption);
  };

  return (
    <div className="PageContent">
      <Container className={styles.ContainerClass}>
        {/* Top heading data */}
        <div className="ArrowIconClass m-0 p-0">
          <Link to="/study-notes" className={styles.signup}>
            <FaArrowLeft size={50} className="BackIcon" />
          </Link>
          <h1 className={styles.heading}>Project detail</h1>
        </div>
        <img src={TopImg} className={Styles.backgroundImage} alt="Home" />

        <div className={Styles.DataBaseHeading}>
          <h6>Database Administration</h6>
          <div className={styles.Create}>
            <h1>Database Analysis</h1>
            <p><strong>Duration :</strong> 2 Hours</p>
          </div>
          <h3>Description</h3>
          <p>
            Our community is being called to reimagine the future. As the only
            university where a renowned design school comes together with
            premier colleges, we are making learning more relevant and
            transformational. We are enriched by the wide range.
          </p>
        </div>

        <div className={Styles.StudyNotesHeading}>
          <img src={QuesAnsIcon} alt="QuesAnsIcon" />
          <h1>{t("QUESTION_ANSWER")}</h1>
        </div>

        <div className={Styles.QuesAns}>
          <div className={Styles.Question}>
            <h1>
              01 Lorem Ipsum Dollar simply Dummy Text Lorem Ipsum simply text?{" "}
            </h1>
            <img
              src={optionsOpen ? Open : Close}
              alt=""
              onClick={toggleOptions}
            />
          </div>
          {optionsOpen && (
            <form className={Styles.Options} onSubmit={handleSubmit}>
              {options.map((option) => (
                <label key={option.value} className={Styles.label}>
                  <input
                    type="radio"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={handleOptionChange}
                  />
                  {option.label}
                </label>
              ))}
              <div className={Styles.SubmitButton}>
                <button
                  type="submit"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>

        <div
          className={Styles.QuesAns}
          style={{ backgroundColor: "#0052AB", color: "#ffff" }}
        >
          <div className={Styles.Question}>
            <h1 style={{ color: "#ffff" }}>
              02 Lorem Ipsum Dollar simply Dummy Text Lorem Ipsum simply text?{" "}
            </h1>
            <img
              src={optionsOpen ? Open : Close}
              alt=""
              onClick={toggleOptions}
            />
          </div>
          {optionsOpen && (
            <form className={Styles.Options} onSubmit={handleSubmit}>
              {options.map((option) => (
                <label key={option.value} className={Styles.label}>
                  <input
                    type="radio"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={handleOptionChange}
                  />
                  {option.label}
                </label>
              ))}
              <div className={Styles.SubmitButton}>
                <button
                  type="submit"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
          <SubmitAnswerModel show={show} handleClose={handleClose} />
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default ProjectDetails;
