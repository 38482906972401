import React from "react";
import TopHeading from "./TopHeading";
import { Container } from "react-bootstrap";
import Catogories from "./Catogories";
import VerbalTopics from "./VerbalTopics";
import Course from "./Course";
import Footer from "../Footer/Footer";

function Home() {
  return (
    <>
      <div className="Content">
        <TopHeading />
        {/* <Course/> */}
        <Catogories />
        <VerbalTopics/>
        <Footer/>
      </div>
    </>
  );
}

export default Home;
