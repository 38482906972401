export const sanitizeHtmlTags = () => ({
  validate: (value) =>
    !/<(\/)?[a-z][\s\S]*>/i.test(value) || "HTML tags are not allowed.",
});

export const ValidationSchema = {
  NAME: {
    required: "NAME_REQUIRED",
  },
  EMAIL: {
    required: "EMAIL_REQUIRED",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "PROVIDE_VALID_EMAIL",
    },
  },
  PASSWORD: {
    required: "ENTER_PASSWORD",
    minLength: {
      value: 8,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 16,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).+$/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },
  CNFPASSWORD: {
    required: "ENTER_CONFIRM_PASSWORD",
    minLength: {
      value: 8,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 16,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).+$/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },
  TITLE: {
    required: "ENTER_TITLE",
    // minLength: {
    //   value: 6,
    //   message: "TITLE_MORE_THAN_6_CH",
    // },
    maxLength: {
      value: 250,
      message: "TITLE_NOT_MORE_THAN_6_CH",
    },
  },
  DATE: {
    required: "DATE_REQUIRED",
  },
  TIME: {
    required: "TIME_REQUIRED",
  },
  DESCRIPTION: {
    required: "ENTER_DESCRIPTION",
    // minLength: {
    //   value: 6,
    //   message: "DESCRIPTION_MUST_6_CH",
    // },
    maxLength: {
      value: 500,
      message: "DESCRIPTION_NOT_MORE_THAN_6_CH",
    },
  },
  DURATION: {
    required: "DURATION_IS_REQUIRED",

    validate: {
      notNegative: (value) => {
        if (value < 1 || value > 99999) {
          return "Please enter valid time duration";
        }
        return true;
      },
    },
  },
  QUESTION: {
    required: "ENTER_QUESTION",
    // minLength: {
    //   value: 6,
    //   message: "QUESTION_MUST_6_CH",
    // },
    maxLength: {
      value: 500,
      message: "QUESTION_NOT_MORE_12_CH",
    },
  },
  OPTION1: {
    required: "ENTER_OPTION",
    // minLength: {
    //   value: 6,
    //   message: "OPTION_MUST_6_CH",
    // },
    // maxLength: {
    //   value: 12,
    //   message: "OPTION_NOT_MORE_THAN_12_CH",
    // },
  },
  OPTION2: {
    required: "Enter option",
    // minLength: {
    //   value: 6,
    //   message: "OPTION_MUST_6_CH",
    // },
    // maxLength: {
    //   value: 12,
    //   message: "OPTION_NOT_MORE_THAN_12_CH",
    // },
  },
  OPTION3: {
    required: "Enter option",
    // minLength: {
    //   value: 6,
    //   message: "OPTION_MUST_6_CH",
    // },
    // maxLength: {
    //   value: 12,
    //   message: "OPTION_NOT_MORE_THAN_12_CH",
    // },
  },
  OPTION4: {
    required: "Enter option",
    // minLength: {
    //   value: 6,
    //   message: "OPTION_MUST_6_CH",
    // },
    // maxLength: {
    //   value: 12,
    //   message: "OPTION_NOT_MORE_THAN_12_CH",
    // },
  },
  VIDEO: {
    required: "PLEASE_UPLOAD_VIDEO",
  },
};
